<template>
  <b-card no-body>
    <b-card-body>
      <search-bar
        :filter-action="filterSearch"
        :search-action="keywordSearch"
      />
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(company_name)="data">
        <div v-if="data.item.company_name">
          {{ data.item.company_name }}
          <div class="text-muted font-small-2">
            {{ data.item.customer_name }}
          </div>
        </div>
        <div v-else>
          {{ data.item.customer_name }}
        </div>
      </template>
      <template #cell(car_title)="data">
        <div class="font-weight-bold">
          {{ data.item.arackodu }}
        </div>
        <div class="font-small-2 text-primary">
          {{ data.item.car_title }}
        </div>
        <div class="font-small-2 text-warning">
          {{ data.item.licence_plate }}
        </div>
      </template>
      <template #cell(sdate)="data">
        <div>
          {{ moment(data.item.sdate).format('DD.MM.YYYY') }}
        </div>
        <div>
          {{ moment(data.item.edate).format('DD.MM.YYYY') }}
        </div>
      </template>
      <template #cell(edate)="data">
        <b-badge
          v-if="moment(data.item.edate).diff(moment(),'days') > 0 && moment(data.item.edate).diff(moment(),'days') > 10"
          variant="success"
        >
          {{ moment(data.item.edate).diff(moment(),'days') }} Gün
        </b-badge>
        <b-badge
          v-else-if="moment(data.item.edate).diff(moment(),'days') > 0 && moment(data.item.edate).diff(moment(),'days') <= 10"
          variant="warning"
        >
          {{ moment(data.item.edate).diff(moment(),'days') }} Gün
        </b-badge>
        <b-badge
          v-else-if="moment(data.item.edate).diff(moment(),'days') < 0"
          variant="danger"
        >
          {{ moment(data.item.edate).diff(moment(),'days') }} Gün
        </b-badge>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <list-dropdown-buttons
            primary-text="Görüntüle"
            :primary-action="'/rental/contracts/view/' + data.item.id"
            :edit-action="'/rental/contracts/edit/' + data.item.id"
          />
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
      <div class="text-muted">
        Toplam {{ dataCounts | toNumber }} adet kayıt bulundu.
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BBadge, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListDropdownButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import SearchBar from '@/views/Rental/Contracts/Index/SearchBar.vue'

const tableName = 'com_rental_contracts'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BBadge,
    BCardBody,
    ListDropdownButtons,
    SearchBar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'company_name',
          label: 'MÜŞTERİ',
          thClass: 'text-nowrap',
        },
        {
          key: 'car_title',
          label: 'ARAÇ BİLGİSİ',
          thClass: 'text-nowrap',
        },
        {
          key: 'sdate',
          label: 'SÖZLEŞME',
          thClass: 'text-nowrap',
        },
        {
          key: 'edate',
          label: 'KALAN GÜN',
          thClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.contract_no AS contract_no`,
          `${tableName}.sdate AS sdate`,
          `${tableName}.edate AS edate`,
          'com_rental_stocks.arackodu AS arackodu',
          'com_rental_stocks.title AS car_title',
          'com_rental_stocks.licence_plate AS licence_plate',
          'com_rental_contract_status.title AS contract_status',
          'com_rental_contract_status.variant AS status_variant',
          'com_customer.company_name AS company_name',
          'com_customer.name AS customer_name',
        ],
        where: {
          'com_rental_contracts.id_com_rental_contract_status': '1',
          'com_rental_stocks.id_com_rent_stock_status': '2',
          'com_rental_contracts.edate <=': this.moment().add(90, 'days').format('YYYY-MM-DD'),
        },
        order_by: ['com_rental_contracts.edate', 'ASC'],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    searchData() {
      return this.$store.getters['rentalContracts/searchData']
    },
    detailFilter() {
      return this.$store.getters['rentalContracts/detailFilter']
    },
    dataList() {
      return this.$store.getters['rentalContracts/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalContracts/dataCounts']
    },
    saveData() {
      return this.$store.getters['rentalContracts/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_customer.company_name': this.searchData.keyword,
        'com_customer.name': this.searchData.keyword,
        'com_rental_stocks.arackodu': this.searchData.keyword,
        'com_rental_stocks.licence_plate': this.searchData.keyword,
        'com_rental_stocks.chasis': this.searchData.keyword,
      }
      this.getDataList()
    },
    filterSearch() {
      const where = {}
      if (this.detailFilter.id_com_rental_contract_status) {
        where['com_rental_contracts.id_com_rental_contract_status'] = this.detailFilter.id_com_rental_contract_status
      }
      if (this.detailFilter.sdate) {
        where['com_rental_contracts.sdate >='] = this.detailFilter.sdate
      }
      if (this.detailFilter.edate) {
        where['com_rental_contracts.edate <='] = this.detailFilter.edate
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('rentalContracts/getDataList', this.dataQuery)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
